import { addMethod, string } from "yup";
import convertStringToNumber from "./convertStringToNumber";

declare module "yup" {
  interface StringSchema {
    localeNumber: (opts?: { decimalPlaces?: number; message?: string }) => StringSchema;
    phone: (message?: string) => StringSchema;
  }
}

addMethod(string, "localeNumber", function ({ decimalPlaces = 2, message = "Invalid number" } = {}) {
  return this.test("validate-number", message, function (value) {
    try {
      convertStringToNumber(value ?? "", { decimalPlaces });
    } catch (ex) {
      const { createError, path } = this;
      return createError({ path, message: ex instanceof Error ? ex.message : JSON.stringify(ex) });
    }

    return true;
  });
});

addMethod(string, "phone", function ({ message = "This is a required field" } = {}) {
  return this.test("phone", message, function (value) {
    const isValid = /\+?[^a-zA-Z\s][\d][^a-z]/.test(value ?? "");

    if (isValid) return true;

    return this.createError({
      path: this.path,
      message: "Invalid contact number"
    });
  });
});
