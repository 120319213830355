import React, { useState } from "react";
import PropTypes from "prop-types";

import Notification from "../components/Notification";

const NotificationContext = React.createContext();

const NotificationProvider = ({ children }) => {
  const [activeNotification, setActiveNotification] = useState(null);

  const sendNotification = notification => {
    setActiveNotification(notification);
  };

  const removeNotification = () => {
    setActiveNotification(null);
  };

  return (
    <NotificationContext.Provider value={{ sendNotification, removeNotification }}>
      {activeNotification && <Notification {...activeNotification} onClose={removeNotification} />}
      {children}
    </NotificationContext.Provider>
  );
};

const NotificationConsumer = NotificationContext.Consumer;

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { NotificationProvider, NotificationConsumer };
export default NotificationContext;
