import React from "react";
import {
  MapCenterIcon,
  ChangeCurrencyIcon,
  ArrowTrendUpRightIcon,
  WidgetsIcon
} from "@bookingcom/bui-assets-react/streamline";

export const FOOTER_LIST_ITEMS = {
  "Help & Information": [
    { text: "About us", href: "https://taxi.booking.com/information/about" },
    {
      text: "Cookie Preferences",
      href: "https://taxi.booking.com/cookie-portal"
    },
    { text: "Help", href: "https://taxi.booking.com/help" },
    {
      text: "Terms and conditions",
      href: "https://taxi.booking.com/information/terms"
    },
    {
      text: "Privacy Notice",
      href: "https://taxi.booking.com/information/privacy"
    },
    {
      text: "Cookie Statement",
      href: "https://taxi.booking.com/information/cookie-statement"
    },
    {
      text: "Affiliate programme",
      href: "https://taxi.booking.com/information/affiliate"
    },
    {
      text: "Partner programme",
      href: "https://portal.rideways.com/become-a-partner"
    }
  ],
  "Popular airport transfers": [
    {
      text: "Heathrow Airport Transfers",
      href: "https://taxi.booking.com/en-gb/airport/transfers/uk/london/lhr"
    },
    {
      text: "Gatwick Airport Transfers",
      href: "https://taxi.booking.com/en-gb/airport/transfers/uk/london/lgw"
    },
    {
      text: "Manchester Airport Transfers",
      href: "https://taxi.booking.com/en-gb/airport/transfers/uk/manchester/man"
    },
    {
      text: "Birmingham Airport Transfers",
      href: "https://taxi.booking.com/en-gb/airport/transfers/uk/birmingham/bhx"
    },
    {
      text: "Liverpool Airport Transfers",
      href: "https://taxi.booking.com/en-gb/airport/transfers/uk/liverpool/lpl"
    },
    {
      text: "Airport Transfers",
      href: "https://taxi.booking.com/en-gb/services/airport-transfers"
    },
    {
      text: "Chauffeur Service",
      href: "https://taxi.booking.com/en-gb/services/chauffeur"
    },
    {
      text: "Minibus Service",
      href: "https://taxi.booking.com/en-gb/services/minibuses"
    },
    {
      text: "Taxi Service",
      href: "https://taxi.booking.com/en-gb/services/taxis"
    }
  ]
};

export const LANDING_HERO_LIST_ITEMS = [
  "Global customer reach to over one million hotel and flight bookers daily",
  "Easy self-billing payment process",
  "Advance bookings to make your dispatch planning easier",
  "Seamless integration with your existing dispatch system possible via XML"
];

export const WORK_WITH_US_REASONS = [
  {
    circleClassName: "reason__icon--primary-lighter",
    icon: <ChangeCurrencyIcon className="reason__icon" />,
    header: "We’re good news for your business",
    description:
      "We are part of an established global company: Booking Holdings Inc., the world's biggest online travel and leisure group. This give us the expertise to offer a seamless booking experience"
  },
  {
    circleClassName: "reason__icon--constructive-lighter",
    icon: <ArrowTrendUpRightIcon className="reason__icon" />,
    header: "We are dedicated to driving growth",
    description:
      "Targeted marketing investment and leveraging our massive travel industry reach to generate incremental volume into your business."
  },
  {
    circleClassName: "reason__icon--callout-lighter",
    icon: <WidgetsIcon className="reason__icon" />,
    header: "We keep things simple",
    description:
      "We find the customers, you provide the high quality vehicles and drivers, we provide friendly help and support, our customers have a great experience, it really is that easy."
  },
  {
    circleClassName: "reason__icon--complement-lighter",
    icon: <MapCenterIcon className="reason__icon" />,
    header: "We have the tools to help you succeed",
    description:
      "You’ll have access to the Partner Portal and driver app, allowing you to manage your bookings, drivers and see reports."
  }
];
