import { useEffect, useRef } from "react";
import { Auth } from "@aws-amplify/auth";
import { useToast } from "@bookingcom/bui-react";
import { useNavigate } from "react-router-dom";

import setAuthorisedHeader from "../utils/setAuthorisedHeader";
import { PATHS } from "../consts";

export class FetchWrapperError extends Error {
  data: Record<string, unknown>;
  statusCode: number;

  constructor(message: string, data: Record<string, unknown>, statusCode: number) {
    super(message);
    this.name = "FetchWrapperError";
    this.data = data;
    this.statusCode = statusCode;
  }
}

const useFetchWrapper = () => {
  const abortControllerRef = useRef(new AbortController());
  const toast = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      abort();
    };
  }, []);

  const abort = () => {
    if (!abortControllerRef.current) return;
    abortControllerRef.current.abort();
  };

  const request = (method: "GET" | "POST" | "PUT" | "DELETE") => {
    return async <T = unknown>(
      url: string,
      queryParams?: string | Record<string, string> | string[][] | URLSearchParams | undefined,
      body?: unknown
    ): Promise<T> => {
      abortControllerRef.current = new AbortController();
      const authHeader = await setAuthorisedHeader();
      let headers: {
        [key: string]: string;
      } = {
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...authHeader
      };
      const requestOptions: RequestInit = {
        method,
        signal: abortControllerRef.current?.signal
      };

      if (queryParams) {
        const params = new URLSearchParams(queryParams);
        url = `${url}?${params.toString()}`;
      }

      if (body) {
        headers = { ...headers, "Content-Type": "application/json" };
        requestOptions.body = JSON.stringify(body);
      }
      requestOptions.headers = headers;

      return fetch(url, requestOptions).then(handleResponse<T>);
    };
  };

  const handleResponse = async <T = unknown>(response: Response) => {
    let data;
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      data = await response.json();
    } else {
      data = await response.text();
    }

    if (!response.ok) {
      const err = new FetchWrapperError("Response not ok", data, response.status);

      if (response.status === 401) {
        toast.show({ text: "Your session is no longer valid" });
        await Auth.signOut();
      }

      if (response.status === 503) {
        navigate(PATHS.ERROR);
      }

      return Promise.reject(err);
    }

    return data as T;
  };

  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
    abort
  };
};

export default useFetchWrapper;
