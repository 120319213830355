import { AVAILABLE_LOCALES, DEFAULT_LOCALE, AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "../consts";

export const browserLocale = () =>
  (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || "en-GB";

export const getLocale = () => {
  return AVAILABLE_LOCALES.includes(browserLocale()) ? browserLocale() : DEFAULT_LOCALE;
};

export const getLanguage = () => {
  const language = browserLocale().split("-")[0];
  return AVAILABLE_LANGUAGES.includes(language) ? language : DEFAULT_LANGUAGE;
};
