import React from "react";
import { FormattedMessage } from "react-intl";

import { ZENDESK_LINK } from "../consts";

const Footer = () => (
  <footer className="footer">
    <section className="footer__company-info">
      <div className="container">
        <p className="footer__print">
          <FormattedMessage
            id="footer.transport"
            defaultMessage="2022 Booking.com Transport Limited trading as Booking.com Rides"
          />
        </p>
        <p className="footer__print">
          <FormattedMessage
            id="footer.booking"
            defaultMessage="Booking.com Rides is part of Booking Holdings Inc., the world leader in online travel &amp; related services"
          />
        </p>
        <div className="footer__policy">
          <a href={ZENDESK_LINK} target="_blank" rel="noopener noreferrer" className="footer__policy--link">
            <FormattedMessage id="footer.help" defaultMessage="Supply Portal Help" />
          </a>
        </div>
      </div>
    </section>
  </footer>
);

export default Footer;
