const classNames = args => {
  let className = "";

  if (typeof args === "string") return args;

  if (Array.isArray(args) && args.length > 0) {
    Object.keys(args).forEach((el, index) => {
      if (classNames(args[index])) {
        return (className += `${className && " "}${classNames(args[index])}`);
      }
    });
  } else {
    for (var key in args) {
      if (args[key] && Object.prototype.hasOwnProperty.call(args, key)) className += `${className && " "}${key}`;
    }
  }
  return className;
};

export default classNames;
