import Luxury from "../../images/cartypes/luxury.png";
import Executive from "../../images/cartypes/executive.png";
import Standard from "../../images/cartypes/standard.png";
import ExecutivePeopleCarrier from "../../images/cartypes/executive-people-carrier.png";
import LargePeopleCarrier from "../../images/cartypes/large-people-carrier.png";
import Minibus from "../../images/cartypes/minibus.png";
import PeopleCarrier from "../../images/cartypes/people-carrier.png";

export const LEADS_FORM_FLEET_SIZES = [
  { text: "1 - 5 Vehicles", value: "1 - 5 Vehicles" },
  { text: "6 - 10 Vehicles", value: "6 - 10 Vehicles" },
  { text: "11 - 20 Vehicles", value: "11 - 20 Vehicles" },
  { text: "21 - 50 Vehicles", value: "21 - 50 Vehicles" },
  { text: "50 - 100 Vehicles", value: "50 - 100 Vehicles" },
  { text: "100+ Vehicles", value: "100+ Vehicles" }
];

export const LEADS_FORM_INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  contactNumber: "",
  companyName: "",
  url: "",
  country: "",
  cities: "",
  airports: "",
  canPickup: "",
  openOffice: "",
  maxBookings: "",
  fleetSize: "",
  vehicleTypes: []
};

export const LEADS_FORM_VEHICLE_TYPES = [
  {
    title: "Standard Sedan",
    description: "Skoda Octavia or similar",
    imageSrc: Standard
  },
  {
    title: "Executive Sedan",
    description: "Mercedes E-Class or similar",
    imageSrc: Executive
  },
  {
    title: "Luxury Sedan",
    description: "Mercedes S-Class or similar",
    imageSrc: Luxury
  },
  {
    title: "People Carrier",
    description: "Peugeot 5008 or similar",
    imageSrc: PeopleCarrier
  },
  {
    title: "Large People Carrier",
    description: "Ford Tourneo or similar",
    imageSrc: LargePeopleCarrier
  },
  {
    title: "Executive People Carrier",
    description: "Mercedes V-Class or similar",
    imageSrc: ExecutivePeopleCarrier
  },
  {
    title: "Minibus",
    description: "Renault Master or similar",
    imageSrc: Minibus
  }
];

export const LEADS_FORM_COUNTRIES = [
  { text: "Åland Islands", value: "Åland Islands" },
  { text: "Albania", value: "Albania" },
  { text: "Algeria", value: "Algeria" },
  { text: "American Samoa", value: "American Samoa" },
  { text: "Andorra", value: "Andorra" },
  { text: "Angola", value: "Angola" },
  { text: "Anguilla", value: "Anguilla" },
  { text: "Antarctica", value: "Antarctica" },
  { text: "Antigua", value: "Antigua" },
  { text: "Argentina", value: "Argentina" },
  { text: "Armenia", value: "Armenia" },
  { text: "Aruba", value: "Aruba" },
  { text: "Australia", value: "Australia" },
  { text: "Austria", value: "Austria" },
  { text: "Azerbaijan", value: "Azerbaijan" },
  { text: "Bahamas", value: "Bahamas" },
  { text: "Bahrain", value: "Bahrain" },
  { text: "Bangladesh", value: "Bangladesh" },
  { text: "Barbados", value: "Barbados" },
  { text: "Belarus", value: "Belarus" },
  { text: "Belgium", value: "Belgium" },
  { text: "Belize", value: "Belize" },
  { text: "Benin", value: "Benin" },
  { text: "Bermuda", value: "Bermuda" },
  { text: "Bhutan", value: "Bhutan" },
  { text: "Bolivia", value: "Bolivia" },
  { text: "Bosnia & Herzegovina", value: "Bosnia & Herzegovina" },
  { text: "Botswana", value: "Botswana" },
  { text: "Bouvet Island", value: "Bouvet Island" },
  { text: "Brazil", value: "Brazil" },
  { text: "British Indian Ocean Territory", value: "British Indian Ocean Territory" },
  { text: "British Virgin Islands", value: "British Virgin Islands" },
  { text: "Brunei", value: "Brunei" },
  { text: "Bulgaria", value: "Bulgaria" },
  { text: "Burkina Faso", value: "Burkina Faso" },
  { text: "Burundi", value: "Burundi" },
  { text: "Cambodia", value: "Cambodia" },
  { text: "Cameroon", value: "Cameroon" },
  { text: "Canada", value: "Canada" },
  { text: "Cape Verde", value: "Cape Verde" },
  { text: "Caribbean Netherlands", value: "Caribbean Netherlands" },
  { text: "Cayman Islands", value: "Cayman Islands" },
  { text: "Central African Republic", value: "Central African Republic" },
  { text: "Chad", value: "Chad" },
  { text: "Chile", value: "Chile" },
  { text: "China", value: "China" },
  { text: "Christmas Island", value: "Christmas Island" },
  { text: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { text: "Colombia", value: "Colombia" },
  { text: "Comoros", value: "Comoros" },
  { text: "Congo, Democratic Republic of the", value: "Congo, Democratic Republic of the" },
  { text: "Congo (Brazzaville)", value: "Congo (Brazzaville)" },
  { text: "Congo - Kinshasa", value: "Congo - Kinshasa" },
  { text: "Cook Islands", value: "Cook Islands" },
  { text: "Costa Rica", value: "Costa Rica" },
  { text: "Côte D’Ivoire", value: "Côte D’Ivoire" },
  { text: "Croatia", value: "Croatia" },
  { text: "Curacao", value: "Curacao" },
  { text: "Cyprus", value: "Cyprus" },
  { text: "Czech Republic", value: "Czech Republic" },
  { text: "Denmark", value: "Denmark" },
  { text: "Djibouti", value: "Djibouti" },
  { text: "Dominica", value: "Dominica" },
  { text: "Dominican Republic", value: "Dominican Republic" },
  { text: "Ecuador", value: "Ecuador" },
  { text: "Egypt", value: "Egypt" },
  { text: "El Salvador", value: "El Salvador" },
  { text: "Equatorial Guinea", value: "Equatorial Guinea" },
  { text: "Eritrea", value: "Eritrea" },
  { text: "Estonia", value: "Estonia" },
  { text: "Eswatini", value: "Eswatini" },
  { text: "Ethiopia", value: "Ethiopia" },
  { text: "Falkland Islands", value: "Falkland Islands" },
  { text: "Faroe Islands", value: "Faroe Islands" },
  { text: "Fiji", value: "Fiji" },
  { text: "Finland", value: "Finland" },
  { text: "France", value: "France" },
  { text: "French Guiana", value: "French Guiana" },
  { text: "French Polynesia", value: "French Polynesia" },
  { text: "French Southern Territories", value: "French Southern Territories" },
  { text: "Gabon", value: "Gabon" },
  { text: "Gambia", value: "Gambia" },
  { text: "Georgia", value: "Georgia" },
  { text: "Germany", value: "Germany" },
  { text: "Ghana", value: "Ghana" },
  { text: "Gibraltar", value: "Gibraltar" },
  { text: "Greece", value: "Greece" },
  { text: "Greenland", value: "Greenland" },
  { text: "Grenada", value: "Grenada" },
  { text: "Guadeloupe", value: "Guadeloupe" },
  { text: "Guam", value: "Guam" },
  { text: "Guatemala", value: "Guatemala" },
  { text: "Guernsey", value: "Guernsey" },
  { text: "Guinea", value: "Guinea" },
  { text: "Guinea-Bissau", value: "Guinea-Bissau" },
  { text: "Guyana", value: "Guyana" },
  { text: "Haiti", value: "Haiti" },
  { text: "Heard & Mcdonald Islands", value: "Heard & Mcdonald Islands" },
  { text: "Honduras", value: "Honduras" },
  { text: "Hong Kong", value: "Hong Kong" },
  { text: "Hong Kong Sar China", value: "Hong Kong Sar China" },
  { text: "Hungary", value: "Hungary" },
  { text: "Iceland", value: "Iceland" },
  { text: "India", value: "India" },
  { text: "Indonesia", value: "Indonesia" },
  { text: "Iraq", value: "Iraq" },
  { text: "Ireland", value: "Ireland" },
  { text: "Isle of Man", value: "Isle of Man" },
  { text: "Israel", value: "Israel" },
  { text: "Italy", value: "Italy" },
  { text: "Ivory Coast", value: "Ivory Coast" },
  { text: "Jamaica", value: "Jamaica" },
  { text: "Japan", value: "Japan" },
  { text: "Jersey", value: "Jersey" },
  { text: "Jordan", value: "Jordan" },
  { text: "Kazakhstan", value: "Kazakhstan" },
  { text: "Kenya", value: "Kenya" },
  { text: "Kiribati", value: "Kiribati" },
  { text: "Kosovo", value: "Kosovo" },
  { text: "Kuwait", value: "Kuwait" },
  { text: "Kyrgyzstan", value: "Kyrgyzstan" },
  { text: "Laos", value: "Laos" },
  { text: "Latvia", value: "Latvia" },
  { text: "Lebanon", value: "Lebanon" },
  { text: "Lesotho", value: "Lesotho" },
  { text: "Liberia", value: "Liberia" },
  { text: "Libya", value: "Libya" },
  { text: "Liechtenstein", value: "Liechtenstein" },
  { text: "Lithuania", value: "Lithuania" },
  { text: "Luxembourg", value: "Luxembourg" },
  { text: "Macau", value: "Macau" },
  { text: "Macedonia", value: "Macedonia" },
  { text: "Madagascar", value: "Madagascar" },
  { text: "Malawi", value: "Malawi" },
  { text: "Malaysia", value: "Malaysia" },
  { text: "Maldives", value: "Maldives" },
  { text: "Mali", value: "Mali" },
  { text: "Malta", value: "Malta" },
  { text: "Marshall Islands", value: "Marshall Islands" },
  { text: "Martinique", value: "Martinique" },
  { text: "Mauritania", value: "Mauritania" },
  { text: "Mauritius", value: "Mauritius" },
  { text: "Mayotte", value: "Mayotte" },
  { text: "Mexico", value: "Mexico" },
  { text: "Moldova", value: "Moldova" },
  { text: "Monaco", value: "Monaco" },
  { text: "Mongolia", value: "Mongolia" },
  { text: "Montenegro", value: "Montenegro" },
  { text: "Montserrat", value: "Montserrat" },
  { text: "Morocco", value: "Morocco" },
  { text: "Mozambique", value: "Mozambique" },
  { text: "Myanmar", value: "Myanmar" },
  { text: "Myanmar (Burma)", value: "Myanmar (Burma)" },
  { text: "Namibia", value: "Namibia" },
  { text: "Nauru", value: "Nauru" },
  { text: "Nepal", value: "Nepal" },
  { text: "Netherlands", value: "Netherlands" },
  { text: "New Caledonia", value: "New Caledonia" },
  { text: "New Zealand", value: "New Zealand" },
  { text: "Nicaragua", value: "Nicaragua" },
  { text: "Niger", value: "Niger" },
  { text: "Nigeria", value: "Nigeria" },
  { text: "Niue", value: "Niue" },
  { text: "Norfolk Island", value: "Norfolk Island" },
  { text: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { text: "North Macedonia", value: "North Macedonia" },
  { text: "Norway", value: "Norway" },
  { text: "Oman", value: "Oman" },
  { text: "Pacific Islands", value: "Pacific Islands" },
  { text: "Pakistan", value: "Pakistan" },
  { text: "Palau", value: "Palau" },
  { text: "Palestine", value: "Palestine" },
  { text: "Palestinian Territories", value: "Palestinian Territories" },
  { text: "Panama", value: "Panama" },
  { text: "Papua New Guinea", value: "Papua New Guinea" },
  { text: "Paraguay", value: "Paraguay" },
  { text: "Peru", value: "Peru" },
  { text: "Philippines", value: "Philippines" },
  { text: "Pitcairn Islands", value: "Pitcairn Islands" },
  { text: "Poland", value: "Poland" },
  { text: "Portugal", value: "Portugal" },
  { text: "Puerto Rico", value: "Puerto Rico" },
  { text: "Qatar", value: "Qatar" },
  { text: "Reunion", value: "Reunion" },
  { text: "Romania", value: "Romania" },
  { text: "Russia", value: "Russia" },
  { text: "Rwanda", value: "Rwanda" },
  { text: "Saint Helena", value: "Saint Helena" },
  { text: "Samoa", value: "Samoa" },
  { text: "San Marino", value: "San Marino" },
  { text: "São Tomé & Príncipe", value: "São Tomé & Príncipe" },
  { text: "Saudi Arabia", value: "Saudi Arabia" },
  { text: "Senegal", value: "Senegal" },
  { text: "Serbia", value: "Serbia" },
  { text: "Seychelles", value: "Seychelles" },
  { text: "Sierra Leone", value: "Sierra Leone" },
  { text: "Singapore", value: "Singapore" },
  { text: "Sint Maarten", value: "Sint Maarten" },
  { text: "Slovakia", value: "Slovakia" },
  { text: "Slovenia", value: "Slovenia" },
  { text: "Solomon Islands", value: "Solomon Islands" },
  { text: "Somalia", value: "Somalia" },
  { text: "South Africa", value: "South Africa" },
  { text: "South Georgia & South Sandwich Islands", value: "South Georgia & South Sandwich Islands" },
  { text: "South Korea", value: "South Korea" },
  { text: "South Sudan", value: "South Sudan" },
  { text: "Spain", value: "Spain" },
  { text: "Sri Lanka", value: "Sri Lanka" },
  { text: "Saint Barthelemy", value: "Saint Barthelemy" },
  { text: "St Helena", value: "St Helena" },
  { text: "St Kitts & Nevis", value: "St Kitts & Nevis" },
  { text: "Saint Lucia", value: "Saint Lucia" },
  { text: "Saint Martin", value: "Saint Martin" },
  { text: "St Pierre & Miquelon", value: "St Pierre & Miquelon" },
  { text: "St Vincent & Grenadines", value: "St Vincent & Grenadines" },
  { text: "Suriname", value: "Suriname" },
  { text: "Sweden", value: "Sweden" },
  { text: "Switzerland", value: "Switzerland" },
  { text: "Taiwan", value: "Taiwan" },
  { text: "Tajikistan", value: "Tajikistan" },
  { text: "Tanzania", value: "Tanzania" },
  { text: "Thailand", value: "Thailand" },
  { text: "Timor-Leste", value: "Timor-Leste" },
  { text: "Tonga", value: "Tonga" },
  { text: "Trinidad & Tobago", value: "Trinidad & Tobago" },
  { text: "Tunisia", value: "Tunisia" },
  { text: "Turkey", value: "Turkey" },
  { text: "Turkmenistan", value: "Turkmenistan" },
  { text: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { text: "UAE", value: "UAE" },
  { text: "Uganda", value: "Uganda" },
  { text: "Ukraine", value: "Ukraine" },
  { text: "United Kingdom", value: "United Kingdom" },
  { text: "United States", value: "United States" },
  { text: "Uruguay", value: "Uruguay" },
  { text: "Us Outlying Islands", value: "Us Outlying Islands" },
  { text: "Us Virgin Islands", value: "Us Virgin Islands" },
  { text: "Uzbekistan", value: "Uzbekistan" },
  { text: "Vanuatu", value: "Vanuatu" },
  { text: "Venezuela", value: "Venezuela" },
  { text: "Vietnam", value: "Vietnam" },
  { text: "Wallis & Futuna", value: "Wallis & Futuna" },
  { text: "Western Sahara", value: "Western Sahara" },
  { text: "Yemen", value: "Yemen" },
  { text: "Zambia", value: "Zambia" },
  { text: "Zimbabwe", value: "Zimbabwe" }
];
