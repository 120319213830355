import React from "react";
import {
  TransportTaxiTypeStandard,
  TransportTaxiTypeLarge,
  TransportTaxiTypeExec,
  TransportTaxiTypeLargeExec,
  TransportPublicBus,
  TransportPublicTrain,
  TransportTaxiTypeExecElectric,
  TransportTaxiTypeStandardElectric
} from "@bookingcom/bui-assets-react/transport";

export const CAR_TYPES_EXTRA = {
  STANDARD: {
    description: "Skoda Octavia or similar",
    icon: <TransportTaxiTypeStandard />
  },
  EXECUTIVE: {
    description: "Mercedes E-Class or similar",
    icon: <TransportTaxiTypeExec />
  },
  LUXURY: {
    description: "Mercedes S-Class or similar",
    icon: <TransportTaxiTypeExec />
  },
  PEOPLE_CARRIER: {
    description: "Peugeot 5008 or similar",
    icon: <TransportTaxiTypeLarge />
  },
  LARGE_PEOPLE_CARRIER: {
    description: "Ford Tourneo or similar",
    icon: <TransportTaxiTypeLarge />
  },
  EXECUTIVE_PEOPLE_CARRIER: {
    description: "Mercedes V-Class or similar",
    icon: <TransportTaxiTypeLargeExec />
  },
  MINIBUS: {
    description: "Renault Master or similar",
    icon: <TransportPublicBus />
  },
  BUS: {
    icon: <TransportPublicBus />
  },
  TRAIN: {
    icon: <TransportPublicTrain />
  },
  ECO_FRIENDLY_STANDARD: {
    icon: <TransportTaxiTypeStandard />
  },
  ELECTRIC_LUXURY: {
    icon: <TransportTaxiTypeExecElectric />
  },
  ELECTRIC_STANDARD: {
    icon: <TransportTaxiTypeStandardElectric />
  }
};
