export const STATUS_OPTIONS = [
  {
    label: "Assign driver",
    value: "ACCEPTED"
  },
  { label: "Cancelled", value: "CANCELLED" },
  {
    label: "Driver Assigned",
    value: "DRIVER_ASSIGNED"
  },
  {
    label: "Acknowledge",
    value: "NEW"
  },
  {
    label: "Pending Amendment",
    value: "PENDING_AMENDMENT"
  },
  {
    label: "Pending Cancellation",
    value: "PENDING_CANCELLATION"
  }
];
