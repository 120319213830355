import React from "react";
import { FormattedMessage } from "react-intl";
import { Container, Text, Button, Box, Icon } from "@bookingcom/bui-react";
import { TransportCarFrontIcon } from "@bookingcom/bui-assets-react/streamline";

import "./NoMatch.css";

export const PAGE_NOT_FOUND = "PAGE_NOT_FOUND";
export const RESET_PAGE_NOT_FOUND = "RESET_PAGE_NOT_FOUND";

const NoMatch = () => {
  const returnHome = () => (window.location.pathname = "");

  return (
    <Container
      className="no-match"
      fullWidth
      mixin={{
        paddingBlockStart: 4,
        paddingBlockEnd: 4
      }}>
      <Box borderRadius="circle" backgroundColor="action_alt" className="--display-inline-block --margin-bottom-4x">
        <Icon svg={<TransportCarFrontIcon />} size="largest" />
      </Box>
      <Text tagName="h2" variant="headline_1">
        404
      </Text>
      <Text tagName="p" variant="body_2">
        <FormattedMessage id="noMatch.message" defaultMessage="We can't find the page you are looking for." />
      </Text>
      <Button
        text={<FormattedMessage id="noMatch.home" defaultMessage="Go home" />}
        onClick={returnHome}
        className="--margin-top-large"
        attributes={{ "data-testid": "button-no-match" }}
      />
    </Container>
  );
};

export default NoMatch;
