import { AwsRum } from "aws-rum-web";
import { Auth } from "@aws-amplify/auth";

let rum;

export const telemetryModifiers = {
  errors: {
    ignore: errorEvent => {
      const errorMessageIgnoreList = ["The user aborted a request."];
      return errorMessageIgnoreList.some(message => errorEvent.message?.includes(message));
    }
  }
};

export const enableRum = async () => {
  if (!window.env.rum) return;

  if (!rum) {
    const APPLICATION_ID = window.env.rum.applicationId;
    const APPLICATION_VERSION = process.env.VERSION;
    const APPLICATION_REGION = window.env.rum.region;

    rum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, {
      sessionSampleRate: window.env.rum.sessionSampleRate,
      endpoint: `https://dataplane.rum.${APPLICATION_REGION}.amazonaws.com`,
      telemetries: window.env.rum.telemetries.map(telemetry => {
        const modifier = telemetryModifiers[telemetry];
        return modifier ? [telemetry, modifier] : telemetry;
      }),
      allowCookies: window.env.rum.allowCookies,
      enableXRay: false,
      disableAutoPageView: true
    });
  }

  const credentials = await Auth.currentCredentials();
  rum.setAwsCredentials(credentials);
};

export const disableRum = async () => {
  if (rum) rum.disable();
};

export const recordPageView = path => {
  if (rum) rum.recordPageView(path);
};

export const recordError = err => {
  if (rum) rum.recordError(err);
};

export const recordEvent = (eventType, eventData) => {
  if (rum) rum.recordEvent(eventType, eventData);
};
