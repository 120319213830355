const convertStringValue = (
  str: string,
  {
    decimalPlaces = null,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    throwOnError = true
  }: { decimalPlaces?: number | null; min?: number; max?: number; throwOnError?: boolean } = {}
) => {
  if (typeof str === "number") return str;

  const hasCommas = str.includes(",");
  const replacementStr = str.replace(/,/g, ".");

  const parts = replacementStr.split(".");

  if (parts.length > 2) {
    return handleError("Cannot have more than one decimal point", throwOnError);
  }

  const converted = Number(replacementStr);

  if (isNaN(converted) || replacementStr.endsWith(".")) {
    return handleError(`${str} is not a valid number`, throwOnError);
  }

  if (converted < min) {
    return handleError(`Please enter a value greater than or equal to ${min}`, throwOnError);
  }

  if (converted > max) {
    return handleError(`Please enter a value less than or equal to ${max}`, throwOnError);
  }

  if (decimalPlaces !== null && parts.length > 1) {
    const decimalPart = parts[1];
    if (decimalPart.length !== decimalPlaces) {
      let suggestedValue = converted.toFixed(decimalPlaces);
      if (hasCommas) suggestedValue = suggestedValue.replace(/\./g, ",");
      return handleError(`Use the format "${suggestedValue}"`, throwOnError);
    }
  }

  return converted;
};

const handleError = (message: string, throwOnError: boolean) => {
  if (throwOnError) {
    throw new Error(message);
  }
};

export default convertStringValue;
