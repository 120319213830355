import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import SupplierContext from "./SupplierContext";
import SessionContext from "./SessionContext";

const GoogleAnalyticsContext = createContext({ userId: null, supplierId: null });

export const GoogleAnalyticsProvider = ({ children }) => {
  const { supplierId } = useContext(SupplierContext);
  const { userId } = useContext(SessionContext);

  return <GoogleAnalyticsContext.Provider value={{ userId, supplierId }}>{children}</GoogleAnalyticsContext.Provider>;
};

GoogleAnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default GoogleAnalyticsContext;
