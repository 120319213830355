import React, { Component } from "react";
import PropTypes from "prop-types";
import api from "../utils/api";
import { API_ENDPOINTS } from "../consts";

const FeatureTogglesContext = React.createContext<{
  isEnabled: (toggleId: string) => boolean;
  isLoading: boolean;
}>({
  isEnabled: () => false,
  isLoading: true
});

class FeatureTogglesProvider extends Component<
  React.PropsWithChildren<{
    pollInterval: number;
    defaultToggles: Record<string, { enabled: boolean }>;
  }>
> {
  state = {
    toggles: this.props.defaultToggles,
    isLoading: true
  };

  static propTypes: { pollInterval: PropTypes.Requireable<number>; defaultToggles: PropTypes.Validator<object> };
  static defaultProps: { pollInterval: number };

  componentDidMount() {
    this.startPolling();
  }

  startPolling = () => {
    const { pollInterval } = this.props;
    setInterval(this.getToggles, pollInterval);
  };

  getToggles = async () => {
    try {
      const response = await api.get(API_ENDPOINTS.FEATURE_TOGGLES);
      this.setState({ toggles: response, isLoading: false });
    } catch (ex) {
      this.setState({ toggles: this.props.defaultToggles, isLoading: false });
    }
  };

  isToggleEnabled = (toggleId: string) => {
    const { toggles } = this.state;
    if (toggles && toggles[toggleId]) {
      return toggles[toggleId].enabled;
    }

    const { defaultToggles } = this.props;
    if (defaultToggles[toggleId]) {
      return defaultToggles[toggleId].enabled;
    }

    return false;
  };

  render() {
    return (
      <FeatureTogglesContext.Provider
        value={{
          isEnabled: this.isToggleEnabled,
          isLoading: this.state.isLoading
        }}>
        {this.props.children}
      </FeatureTogglesContext.Provider>
    );
  }
}

FeatureTogglesProvider.propTypes = {
  pollInterval: PropTypes.number,
  defaultToggles: PropTypes.object.isRequired
};

FeatureTogglesProvider.defaultProps = {
  pollInterval: 60000
};

const FeatureTogglesConsumer = FeatureTogglesContext.Consumer;

export { FeatureTogglesProvider, FeatureTogglesConsumer };

export default FeatureTogglesContext;
