import { authAccessTokenJwt } from "../aws/amplify/auth";

const setAuthorisedHeader = async () => {
  try {
    const jwt = await authAccessTokenJwt();
    return {
      Authorization: `Bearer ${jwt}`
    };
  } catch (ex) {
    return {
      Authorization: 'Bearer ""'
    };
  }
};

export default setAuthorisedHeader;
