import qs from "qs";

import setAuthorisedHeader from "./setAuthorisedHeader";
/**
 * @deprecated replaced by {@link [useFetchWrapper](../hooks/useFetchWrapper.ts)}
 */
const api = {
  post: (url, data = {}) =>
    api.fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    }),

  put: (url, data = {}, queryParams = null) => {
    if (queryParams) {
      url += qs.stringify(queryParams, { addQueryPrefix: true, encode: false });
    }

    return api.fetch(url, {
      method: "PUT",
      body: JSON.stringify(data)
    });
  },

  get: (url, queryParams, append = "") =>
    api
      .fetch(
        `${url}${qs.stringify(queryParams, {
          addQueryPrefix: true,
          encode: false
        })}${append}`,
        {
          method: "GET"
        }
      )
      .then(response => (typeof response === "string" && JSON.parse(response)) || response),

  delete: url =>
    api.fetch(url, {
      method: "DELETE"
    }),

  parseJSON: response => response.text().then(text => (text ? JSON.parse(text) : {})),

  handleErrors: async response => {
    if (!response.ok) {
      const error = {
        status: response.status,
        statusText: response.statusText
      };

      try {
        const body = await response.json();
        error.body = body;
      } catch (ex) {
        error.body = {};
      }

      throw error;
    }

    return response;
  },

  fetch: async (url, params, responseType = "json") => {
    const auth = await setAuthorisedHeader();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...auth
    };

    return fetch(url, {
      headers,
      method: "GET",
      ...params
    })
      .then(api.handleErrors)
      .then(response => (responseType === "json" && api.parseJSON(response)) || response.text());
  }
};

export default api;
