import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { SheetContainer, Text, Button, Icon } from "@bookingcom/bui-react";
import { ArrowNavRightIcon, ArrowNavLeftIcon, CircleIcon } from "@bookingcom/bui-assets-react/streamline";

import DriverApp2x from "../../images/driver-app@2x.png";
import DriverApp3x from "../../images/driver-app@3x.png";
import PortalHelp2x from "../../images/help@2x.png";
import PortalHelp3x from "../../images/help@3x.png";
import { ZENDESK_LINK } from "../../consts";

const WalkthroughButton = ({ text, state }) => {
  return <div className={`walkthrough-button walkthrough-button--${state}`}>{text}</div>;
};

WalkthroughButton.propTypes = {
  text: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
};

const Welcome = () => (
  <div>
    <Text variant="strong_2">Managing your bookings</Text>
    <Text className="--margin-bottom-large" variant="body_2">
      The Portal is how you view and manage all your bookings (unless you&apos;re using our API product). You can
      acknowledge or decline bookings, assign drivers, and add new drivers to your account.
    </Text>

    <Text variant="strong_2">Interacting with bookings</Text>
    <Text className="--margin-bottom-large" variant="body_2">
      To acknowledge a booking and assign a driver, simply click or tap the button on the booking. You can also add a
      new driver this way.
    </Text>
    <div className="walkthrough-welcome__states-container">
      <WalkthroughButton text="Accept" state="accept" />
      <Icon className="--margin-horizontal-medium" svg={<ArrowNavRightIcon />} />
      <WalkthroughButton text="Assign Driver" state="assign" />
      <Icon className="--margin-horizontal-medium" svg={<ArrowNavRightIcon />} />
      <WalkthroughButton text="Driver Assigned" state="assigned" />
    </div>
  </div>
);

const DriverApp = () => (
  <div className="walkthrough-driverapp">
    <img
      alt="Driver App"
      className="--vertical-align-middle"
      sizes="(max-width: 1024px) 512px, 480px"
      srcSet={`${DriverApp2x} 1024w, ${DriverApp3x} 1025w`}
    />
    <div className="--margin-left-large">
      <Text variant="strong_2">Inform your drivers and get paid on time</Text>
      <Text variant="body_2">
        The driver app lets your drivers see the bookings they&apos;re assigned to. Provides essential information, such
        as passenger contact and pick-up details. Ensures you get paid on time by sending journey progress to
        Booking.com Rides.
      </Text>
    </div>
  </div>
);

const Help = () => (
  <div className="walkthrough-help">
    <img
      alt="Portal Help"
      className="--margin-bottom-large"
      sizes="(max-width: 1024px) 480px, 480px"
      srcSet={`${PortalHelp2x} 1024w, ${PortalHelp3x} 1025w`}
    />
    <div className="--margin-left-large">
      <Text className="--margin-bottom-large" variant="body_2">
        We hope you find the Taxi Partner Portal easy to use. If you need more support, you can find guides and
        walk-throughs on our dedicated help page.
      </Text>
      <Button text="View help page" variant="tertiary" href={ZENDESK_LINK} attributes={{ target: "_blank" }} />
    </div>
  </div>
);

const STEPS = [
  { title: "Welcome to the Taxi Partner Portal", content: <Welcome /> },
  { title: "Driver App", content: <DriverApp /> },
  { title: "Taxi Partner Portal Help", content: <Help /> }
];

const Walkthrough = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = useMemo(() => currentStep === STEPS.length - 1, [currentStep]);

  const handleNextStep = () => {
    if (isLastStep) {
      return onComplete();
    }

    setCurrentStep(currentStep + 1);
  };

  return (
    <SheetContainer
      active
      size="large"
      title={STEPS[currentStep].title}
      onCloseTrigger={onComplete}
      attributes={{ "data-testid": "walkthrough-modal" }}
      position="center">
      <div className="walkthrough__content">{STEPS[currentStep].content}</div>

      <div className="walkthrough__footer">
        <div className="walkthrough__progress">
          {STEPS.map((_, index) => (
            <Icon
              key={`step-${index}`}
              svg={<CircleIcon />}
              size="small"
              color={index <= currentStep ? "action" : "neutral_alt"}
              className="--margin-right-medium"
            />
          ))}
        </div>

        <div>
          {currentStep > 0 && (
            <Button
              text="Previous"
              onClick={() => setCurrentStep(currentStep - 1)}
              variant="secondary"
              className="--margin-right-medium"
              icon={<ArrowNavLeftIcon />}
              iconPosition="start"
              attributes={{ "data-testid": "walkthrough-previous-button" }}
            />
          )}
          <Button
            text={currentStep < STEPS.length - 1 ? "Next" : "Done"}
            onClick={handleNextStep}
            icon={!isLastStep && <ArrowNavRightIcon />}
            iconPosition="end"
            attributes={{ "data-testid": "walkthrough-next-button" }}
          />
        </div>
      </div>
    </SheetContainer>
  );
};

Walkthrough.propTypes = {
  onComplete: PropTypes.func.isRequired
};

export default Walkthrough;
