import React from "react";
import PropTypes from "prop-types";
import { EmptyState } from "@bookingcom/bui-react";

import { recordError } from "../aws/rum";

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    recordError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <EmptyState
          text="We've had an issue loading the page"
          button={{ text: "Return home", href: "/" }}
          className="--margin-vertical-large"
        />
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
