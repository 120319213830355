export const KEYCODES = {
  ESCAPE: 27,
  SPACE: 32,
  ENTER: 13,
  UP_ARROW: 38,
  DOWN_ARROW: 40
};

export const KEYS = {
  TAB: "Tab",
  SPACE: " ",
  ENTER: "Enter",
  ESCAPE: "Escape",
  BACKSPACE: "Backspace",
  ARROW_UP: "ArrowUp",
  ARROW_DOWN: "ArrowDown",
  PAGE_DOWN: "PageDown",
  PAGE_UP: "PageUp",
  HOME: "Home",
  END: "End"
};
