import React from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { Banner, Text } from "@bookingcom/bui-react";

let modalRoot = document.getElementById("modal");
if (!modalRoot) {
  modalRoot = document.createElement("div");
  modalRoot.setAttribute("id", "modal");
  document.body.appendChild(modalRoot);
}

const Notification = ({ title, text, actions = [], onClose = () => null }) =>
  createPortal(
    <div className="notification" data-testid="notification">
      <Banner className="notification__banner" actions={actions} onClose={onClose}>
        <Text variant="display_one" className="--margin-bottom-medium">
          {title}
        </Text>
        <Text className="--margin-bottom-medium" variant="body_2">
          {text}
        </Text>
      </Banner>
    </div>,
    modalRoot
  );

Notification.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  actions: PropTypes.array,
  onClose: PropTypes.func
};

export default Notification;
