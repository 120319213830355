import { useEffect } from "react";

const CookieBanner = () => {
  useEffect(() => {
    const oneTrustSDKScript = document.createElement("script");
    const dataDomainScriptAttr = document.createAttribute("data-domain-script");
    dataDomainScriptAttr.value = window.env.cookieBannerId;

    oneTrustSDKScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    oneTrustSDKScript.async = true;
    oneTrustSDKScript.lang = true;
    oneTrustSDKScript.type = "text/javascript";
    oneTrustSDKScript.attributes.setNamedItem(dataDomainScriptAttr);

    const optanonWrapperScript = document.createElement("script");

    optanonWrapperScript.async = true;
    optanonWrapperScript.innerHTML = function OptanonWrapper() {
      window.dataLayer.push({ event: "OneTrustGroupsUpdated" });
    };

    document.head.appendChild(oneTrustSDKScript, optanonWrapperScript);
  }, []);

  return null;
};

export default CookieBanner;
