import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

import useFetchWrapper from "../hooks/useFetchWrapper";
import SessionContext from "../context/SessionContext";
import { API_ENDPOINTS } from "../consts";

const DriversContext = React.createContext();

export const DriversProvider = ({ children }) => {
  const { userId } = useContext(SessionContext);
  const [drivers, setDrivers] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [assignedBookings, setAssignedBookings] = useState({});
  const fetchWrapper = useFetchWrapper();

  useEffect(() => {
    if (!userId) {
      reset();
    }
  }, [userId]);

  const fetchDrivers = async () => {
    setError(null);
    setLoading(true);

    try {
      const response = await fetchWrapper.get(API_ENDPOINTS.DRIVERS);
      setDrivers(response.drivers);
    } catch (ex) {
      setError(true);
    }

    setLoading(false);
  };

  const addDriver = driver => {
    return setDrivers(prev => [...prev, driver]);
  };

  const updateDriver = updatedDriver => {
    return setDrivers(prev =>
      prev.map(driver => {
        if (driver.id === updatedDriver.id) {
          return {
            ...driver,
            first_name: updatedDriver.first_name,
            last_name: updatedDriver.last_name,
            supplier_location_ids: updatedDriver.supplier_location_ids
          };
        }

        return driver;
      })
    );
  };

  const removeDriver = driverId => {
    return setDrivers(prev => prev.filter(driver => driver.id !== driverId));
  };

  const addAssignedBooking = (bookingRef, driverId) => {
    setAssignedBookings(prev => ({ ...prev, [bookingRef]: driverId }));
  };

  const getDriversByLocationId = locationId => {
    if (!drivers) {
      return [];
    }

    return drivers.filter(driver => {
      const driverLocations = driver.supplier_location_ids.map(id => parseInt(id, 10));

      return driverLocations.includes(locationId);
    });
  };

  const getAssignedDriverByBookingId = (bookingId, assignedDriverId) => {
    if (!drivers) return null;

    const driverId = assignedBookings[bookingId] ?? assignedDriverId;
    return driverId ? drivers.find(driver => driver.id === driverId) : null;
  };

  const reset = () => {
    setDrivers(null);
    setAssignedBookings({});
  };

  return (
    <DriversContext.Provider
      value={{
        drivers,
        error,
        isDriversLoading: loading,
        getAssignedDriverByBookingId,
        fetchDrivers,
        getDriversByLocationId,
        addDriver,
        updateDriver,
        removeDriver,
        addAssignedBooking
      }}>
      {children}
    </DriversContext.Provider>
  );
};

DriversProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default DriversContext;
