import { getLocale, getLanguage } from "../utils/locale";
import { flattenMessages } from "../utils/flattenMessages";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/es";

import messagesGB from "./en";
import messagesES from "./es";

export const location = getLocale();
const language = getLanguage();

const messages = { ...messagesGB, ...messagesES };

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.locale(language);

export const translations = flattenMessages(messages[language]);
