import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import PropTypes from "prop-types";
import { Hub, HubPayload } from "@aws-amplify/core";
import { mutate } from "swr";

import { authCurrentSession, authDecodedToken } from "../aws/amplify/auth";
import { enableRum, disableRum, recordError } from "../aws/rum";

const SIGN_OUT_EVENT = "signOut";
const SIGN_IN_EVENT = "signIn";
const AUTH_CHANNEL = "auth";
interface Session {
  userId?: string | null;
  supplierLocationIds: number[];
  groups: string[];
  isLoggedIn: boolean;
  loading: boolean;
}
const SessionContext = React.createContext<{
  hasGroup: (group: string) => boolean;
  userId?: string | null;
  isLoggedIn: boolean;
  supplierLocationIds: number[];
  showWalkthrough: boolean;
  setShowWalkthrough: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  hasGroup: () => false,
  userId: null,
  isLoggedIn: false,
  supplierLocationIds: [],
  showWalkthrough: false,
  setShowWalkthrough: () => null
});

const SessionContextProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<Session>({
    userId: null,
    supplierLocationIds: [],
    groups: [],
    isLoggedIn: false,
    loading: true
  });
  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const hubListenerCancel = useRef<() => void>();

  useEffect(() => {
    hubListenerCancel.current = Hub.listen(AUTH_CHANNEL, ({ payload }) => onAuthEvent(payload));
    setInitialAuthState();

    return () => {
      hubListenerCancel.current ? hubListenerCancel.current() : null;
    };
  }, []);

  const setInitialAuthState = async () => {
    try {
      await authCurrentSession();
      updateAuthState(true);
    } catch (ex) {
      updateAuthState(false);
    }

    setSession(prev => ({ ...prev, loading: false }));
  };

  const onAuthEvent = ({ event, data }: HubPayload) => {
    switch (event) {
      case SIGN_OUT_EVENT:
        updateAuthState(false, data);
        break;

      case SIGN_IN_EVENT:
        updateAuthState(true);
        break;

      default:
        break;
    }
  };

  const updateAuthState = (authenticated: boolean, eventData?: { attributes?: Record<string, unknown> }) => {
    setSession(prev => ({ ...prev, isLoggedIn: authenticated }));
    if (authenticated) {
      extractSessionData();
      enableRum();
    } else {
      if (eventData?.attributes?.sub) {
        localStorage.removeItem(`REPORTS_CONTACT_WARNING_${eventData.attributes.sub}`);
      }

      clearCache();
      clearSessionData();
      disableRum();
    }
  };

  const extractSessionData = async () => {
    try {
      const tokenPayload = await authDecodedToken();
      const {
        "cognito:groups": tokenGroups,
        "custom:supplier_location_id": tokenSupplierLocationIds,
        sub
      } = tokenPayload;

      setSession(prev => ({
        ...prev,
        userId: sub,
        supplierLocationIds: tokenSupplierLocationIds.split(",").map(Number),
        groups: tokenGroups ?? []
      }));
    } catch (ex) {
      recordError(ex);
    }
  };

  const clearSessionData = () => {
    setSession(prev => ({ ...prev, userId: null, groups: [], supplierLocationIds: [] }));
  };

  const clearCache = () => {
    mutate(() => true, undefined, { revalidate: false });
  };

  const hasGroup = (group: string) => {
    return session.groups?.includes(group);
  };

  return (
    <SessionContext.Provider
      value={{
        isLoggedIn: session.isLoggedIn,
        hasGroup,
        userId: session.userId,
        supplierLocationIds: session.supplierLocationIds,
        showWalkthrough,
        setShowWalkthrough
      }}>
      {!session.loading ? children : null}
    </SessionContext.Provider>
  );
};

SessionContextProvider.propTypes = {
  children: PropTypes.node
};

const SessionConsumer = SessionContext.Consumer;

export { SessionContextProvider, SessionConsumer };

export default SessionContext;
