import React from "react";
import PropTypes from "prop-types";

import Footer from "../components/footer";
import classNames from "../utils/classNames";

const Container = ({ children, hideFooter = false, fullWidth = false, paddingBottom = false }) => (
  <>
    <main className="layout">
      <div className={classNames(["--min-height", { "--no-footer-padding": paddingBottom }])}>
        <div className={classNames(["layout-content", { "layout-content--full-width": fullWidth }])}>{children}</div>
      </div>
    </main>

    {!hideFooter && <Footer />}
  </>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  hideFooter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  paddingBottom: PropTypes.bool
};

export default Container;
