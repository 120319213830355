import React from "react";
import { createRoot } from "react-dom/client";
import { IntlProvider } from "react-intl";
import ReactGA from "react-ga";
import { BUIProvider } from "@bookingcom/bui-react";
import TravellerTheme from "@bookingcom/bui-react/themes/traveller";
import { SWRConfig } from "swr";
import { BrowserRouter } from "react-router-dom";

import { location, translations } from "./i18n";
import { GA_TRACKING_ID, FT_POLL_INTERVAL, FT_DEFAULTS } from "./consts";
import ErrorBoundary from "./containers/ErrorBoundary";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import RootRouter from "./routes";
import {
  FeatureTogglesProvider,
  GoogleAnalyticsProvider,
  NotificationProvider,
  SessionContextProvider,
  SupplierProvider
} from "./context";
import { DriversProvider } from "./context/DriversContext";
import "./aws/amplify/config";
import "./index.scss";
import "./utils/yupSchemaMethods";

ReactGA.initialize(GA_TRACKING_ID);

if (process.env.NODE_ENV !== "production") {
  window[`ga-disable-${GA_TRACKING_ID}`] = true;
}

const urlParams = new URLSearchParams(window.location.search);
const themeMode = urlParams.get("themeMode") || "light";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        dedupingInterval: 100
      }}>
      <BrowserRouter>
        <FeatureTogglesProvider pollInterval={FT_POLL_INTERVAL} defaultToggles={FT_DEFAULTS}>
          <NotificationProvider>
            <SessionContextProvider>
              <SupplierProvider>
                <DriversProvider>
                  <CookieBanner />
                  <IntlProvider locale={location} messages={translations}>
                    <GoogleAnalyticsProvider>
                      <BUIProvider theme={TravellerTheme} defaultThemeMode={themeMode}>
                        <RootRouter />
                      </BUIProvider>
                    </GoogleAnalyticsProvider>
                  </IntlProvider>
                </DriversProvider>
              </SupplierProvider>
            </SessionContextProvider>
          </NotificationProvider>
        </FeatureTogglesProvider>
      </BrowserRouter>
    </SWRConfig>
  </ErrorBoundary>
);
