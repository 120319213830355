export const INTL_LOGIN_TITLE = {
  id: "login.title",
  defaultMessage: "Login"
};

export const INTL_LOGIN_FORGOT_PASSWORD = {
  id: "login.forgotPassword",
  defaultMessage: "Forgotten password"
};

export const INTL_INPUT_FIELD_EMAIL = {
  id: "inputField.email",
  defaultMessage: "Email"
};

export const INTL_INPUT_FIELD_PASSWORD = {
  id: "inputField.password",
  defaultMessage: "Password"
};

export const INTL_INPUT_FIELD_SHOW = {
  id: "inputField.show",
  defaultMessage: "Show"
};

export const INTL_INPUT_FIELD_HIDE = {
  id: "inputField.hide",
  defaultMessage: "Hide"
};

export const INTL_BUTTON_SIGN_IN = {
  id: "button.signIn",
  defaultMessage: "Sign In"
};
