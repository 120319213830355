export const CANCELLATION_REASON = {
  CANT_FULFILL_REQUEST: "CANT_FULFILL_REQUEST",
  INCOMPLETE_BOOKING_DETAILS: "INCOMPLETE_BOOKING_DETAILS",
  NO_AVAILABILITY: "NO_AVAILABILITY",
  RATE_ERROR: "RATE_ERROR",
  ROAD_CLOSURE: "ROAD_CLOSURE",
  OTHER: "OTHER"
};

export const REASON_OPTIONS = [
  {
    id: CANCELLATION_REASON.CANT_FULFILL_REQUEST,
    formattedMessage: {
      id: "popupModal.cancelBookingReason.cantFulfillRequest",
      defaultMessage: "Can't fulfill customer's request"
    },
    moreDetail: false
  },
  {
    id: CANCELLATION_REASON.INCOMPLETE_BOOKING_DETAILS,
    formattedMessage: {
      id: "popupModal.cancelBookingReason.incompleteBookingDetails",
      defaultMessage: "Incomplete booking details"
    },
    moreDetail: false
  },
  {
    id: CANCELLATION_REASON.NO_AVAILABILITY,
    formattedMessage: {
      id: "popupModal.cancelBookingReason.noAvailability",
      defaultMessage: "No availability"
    },
    moreDetail: false
  },
  {
    id: CANCELLATION_REASON.RATE_ERROR,
    formattedMessage: {
      id: "popupModal.cancelBookingReason.rateError",
      defaultMessage: "Rate error"
    },
    moreDetail: false
  },
  {
    id: CANCELLATION_REASON.ROAD_CLOSURE,
    formattedMessage: {
      id: "popupModal.cancelBookingReason.roadClosure",
      defaultMessage: "Road closure"
    },
    moreDetail: false
  },
  {
    id: CANCELLATION_REASON.OTHER,
    formattedMessage: {
      id: "popupModal.cancelBookingReason.other",
      defaultMessage: "Other"
    },
    moreDetail: true
  }
];
