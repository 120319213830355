import ReactGA, { ga } from "react-ga";

export const sendEvent = (category, action, label, metricsAndDimensions = null) => {
  if (metricsAndDimensions) {
    return ga("send", "event", category, action, label, metricsAndDimensions);
  }

  return ReactGA.event({ category, action, label });
};

export const DIMENSIONS = {
  supplierId: "dimension1",
  inBetaProgram: "dimension2",
  fixedRouteName: "dimension4",
  fixedRouteIata: "dimension5",
  rideRating: "dimension6",
  incidentType: "dimension7",
  driverEvents: "dimension8"
};

export const METRICS = {
  fixedRateAmount: "metric1"
};
