const ERRORS = {
  REQUIRED_FIELD: {
    id: "inputField.error.required",
    defaultMessage: "This is a required field"
  },
  INVALID_PHONE: {
    id: "inputField.error.invalidPhone",
    defaultMessage: "You must provide a valid phone number"
  },
  INVALID_EMAIL: {
    id: "inputField.error.invalidEmail",
    defaultMessage: "You must provide a valid email"
  },
  INVALID_BOOKING_ID: {
    id: "inputField.error.invalidBookingID",
    defaultMessage: "Please enter the full 8 digit ID"
  }
};

export default ERRORS;
