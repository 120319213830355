import React, { useState, useEffect, useContext, PropsWithChildren } from "react";
import PropTypes from "prop-types";

import SessionContext from "./SessionContext";
import Api from "../utils/api";
import { API_ENDPOINTS } from "../consts";
import { SupplierLocation } from "../types/SupplierLocation";
interface SupplierContextValue {
  supplierLocations: SupplierLocation[];
  isSupplierLoading: boolean;
  isLocationsLoading: boolean;
  hasLocationsError: boolean;
  getSupplierLocations: () => Promise<void>;
  supplierId: number | undefined;
  hasExportEnabled: boolean;
  preferredPartnerStatusEnabled: boolean;
  hasSupplierError: boolean;
}
interface Supplier {
  id: number;
  name: string;
  bookingsExportEnabled: boolean;
  preferredPartnerStatusEnabled: boolean;
}

const SupplierContext = React.createContext<SupplierContextValue>({
  getSupplierLocations: async () => {
    return;
  },
  supplierLocations: [],
  isSupplierLoading: false,
  isLocationsLoading: false,
  hasLocationsError: false,
  supplierId: undefined,
  hasExportEnabled: false,
  preferredPartnerStatusEnabled: false,
  hasSupplierError: false
});
const SupplierProvider = ({ children }: PropsWithChildren) => {
  const { userId } = useContext(SessionContext);
  const [supplier, setSupplier] = useState<Supplier | null>();
  const [supplierLocations, setSupplierLocations] = useState<SupplierLocation[]>([]);
  const [isSupplierLoading, setIsSupplierLoading] = useState(false);
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);
  const [hasLocationsError, setHasLocationsError] = useState(false);
  const [hasSupplierError, setHasSupplierError] = useState(false);

  useEffect(() => {
    if (userId) {
      getSupplier();
      getSupplierLocations();
      return;
    }
    setSupplier(null);
    setSupplierLocations([]);
  }, [userId]);

  const getSupplier = async () => {
    setIsSupplierLoading(true);
    try {
      const supplier = await Api.get(API_ENDPOINTS.SUPPLIER);
      setSupplier(supplier);
    } catch (ex) {
      setHasSupplierError(true);
    }
    setIsSupplierLoading(false);
  };

  const getSupplierLocations = async () => {
    try {
      setIsLocationsLoading(true);
      const locations = await Api.get(API_ENDPOINTS.SUPPLIER_LOCATIONS);
      setHasLocationsError(false);
      setSupplierLocations(locations);
      setIsLocationsLoading(false);
    } catch (ex) {
      setHasLocationsError(true);
      setIsLocationsLoading(false);
    }
  };

  const getSupplierId = () => {
    return supplier?.id;
  };

  const hasExportEnabled = () => {
    return supplier?.bookingsExportEnabled || false;
  };

  return (
    <SupplierContext.Provider
      value={{
        supplierLocations,
        isSupplierLoading,
        isLocationsLoading,
        hasLocationsError,
        getSupplierLocations,
        supplierId: getSupplierId(),
        hasExportEnabled: hasExportEnabled(),
        preferredPartnerStatusEnabled: supplier?.preferredPartnerStatusEnabled || false,
        hasSupplierError
      }}>
      {children}
    </SupplierContext.Provider>
  );
};

const SupplierConsumer = SupplierContext.Consumer;

SupplierProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { SupplierProvider, SupplierConsumer };
export default SupplierContext;
